import "./App.css";

function App() {
  return (
    <div>
      <h1>Stock Pulse Pro</h1>
      <p>All in one inventory management system for businesses</p>
      <h2>Main features</h2>
      <ol>
        <li>Managing the inventory</li>
        <li>Overall analytics of the business</li>
        <li>Alerts and AI-based Suggestions</li>
        <li>Integrating the inventory with online platforms</li>
      </ol>
    </div>
  );
}

export default App;
